export const environment = {
  production: false,
  services: {
    rhemaServer: 'https://api.rhema.app/api'
    //rhemaServer: 'http://localhost:3000/api'
    },
  fileURL: 'https://s3.us-east-2.amazonaws.com/prod-audio-bucket/rhema-prod-files/',
  videoURL: 'https://s3.us-east-2.amazonaws.com/prod-audio-bucket/rhema-video-files/',
  supportURL: 'https://s3.us-east-2.amazonaws.com/prod-audio-bucket/rhema-support-files/',
};
